import React from "react"
import LPLayout, { LayoutColor } from "../components/landing-page/lp-layout"
import GradientText from "../components/landing-page/gradient-text"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <LPLayout color={LayoutColor.transparent}>
      <main className="flex flex-col items-center max-w-7xl w-full mx-auto xs:px-5 xl:px-0 z-10 xs:mt-36 md:mt-44">
        <div className="grid grid-cols-12">
          <div className="col-span-12 mb-10 md:col-span-6 md:mb-0 text-center md:!text-start flex flex-col self-center">
            <div>
              <GradientText className="uppercase font-normal text-sm lg:text-base">
                Uh oh!
              </GradientText>
            </div>

            <h1 className="font-poppins font-semibold text-[40px] lg:text-[80px]">
              404.
            </h1>
            <div>
              <GradientText className="font-poppins font-semibold text-[40px] lg:text-[80px]">
                {t("pageNotFound")}
              </GradientText>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <StaticImage src="../images/404-image.png" alt="404 Page Image" />
          </div>
        </div>
      </main>
      <div className="overflow-hidden absolute z-0 h-full w-full">
        <div className="max-w-7xl mx-auto relative h-full w-full">
          <div className="absolute bottom-[73px] left-[-356px] blur-3xl h-[1021px] w-[1104px]">
            <StaticImage src="../images/blur1.png" alt="Blur Image" />
          </div>

          <div className="absolute top-[-12px] right-[-556px] blur-3xl h-[130px] w-[390px]">
            <StaticImage src="../images/blur2.png" alt="Blur Image" />
          </div>

          <div className="absolute top-[45%] right-0 blur-3xl h-[354px] w-[807px] rotate-[-5.46deg]">
            <StaticImage src="../images/blur3.png" alt="Blur Image" />
          </div>
        </div>
      </div>
    </LPLayout>
  )
}

export default NotFoundPage
